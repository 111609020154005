import {
  getDocs,
  collection,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { userService } from "./user.service";
import { zipCodeService } from "./zip_code.service";
export const productService = {
  getProductGroupList,
  getProductGroupListByType,
  getProductGroupListbyApp,
  getProductList,
  getProductGroupID,
  getLegacyInfo,
  upload_csv_products,
  update_products,
  getBackupList,
  execute_back_up,
  create_backup,
  getSuggestedProductGroupListByType,
  getProductById,
  SuggestTariff,
  getUserProductGroups,
  SuggestTarifWithPriority,
  getProductGroupById,
  checkProductAvailable,
  getTotalCostParMonth, getUserProductGroupsWithPriority,is_online_tariff_only
};

async function getProductById(product_id) {
  const docRef = doc(db, "xc_product", product_id);
  return await getDoc(docRef);
}
async function is_online_tariff_only(product_id) {
  const docRef = doc(db, "xc_product", product_id);
  const docSnap = await getDoc(docRef);
  var result = docSnap.data();
  return result.is_online_tariff_only;
}
async function getProductGroupById(id) {
  const docRef = doc(db, "xc_product_group", id);
  return await getDoc(docRef);
}
async function getProductGroupList() {
  const queryRef = query(
    collection(db, "xc_product_group"),
    where("is_deleted", "==", false),
    where("active", "==", 1)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getProductGroupListbyApp() {
  const queryRef = query(
    collection(db, "xc_product_group"),
    where("app", "==", 1)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}

async function getProductGroupListByType(type) {
  const queryRef = query(
    collection(db, "xc_product_group"),
    where("product_type", "==", type),
    where("active", "==", 1)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}
async function getSuggestedProductGroupListByType(type, priority) {

  const queryRef = query(
    collection(db, "xc_product_group"),
    where("product_type", "==", type),
    where("active", "==", 1),
    where("standard", "==", priority)
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}
async function getProductList(plzArray) {
  const queryRef = query(
    collection(db, "xc_product"),
    where("is_deleted", "==", false),
    where("plz", "array-contains", plzArray[0])
  );
  const querySnapshot = await getDocs(queryRef);
  const formattedArray = await formatQuerySnapshotToArray(querySnapshot);
  return formattedArray;
}
const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};
async function getProductGroupID(contract) {
  let productgroup = "";
  try {
    const productGroup = doc(db, "xc_product", contract.product_id);
    const querySnapshot = await getDoc(productGroup);

    if (querySnapshot.exists()) {
      return querySnapshot.data().product_group[0];
    }
  } catch (error) {
    console.log(error);
  }
}
async function getLegacyInfo(product_group) {
  try {
    const links = {
      contract_legal: "",
      link_text1: "",
      link_text2: "",
      link_text3: "",
      link_url1: "",
      link_url2: "",
      link_url3: "",
      sepa_legal: "",
    };

    const sepa = doc(db, "xc_product_group", product_group);

    const querySnapshot = await getDoc(sepa);

    if (querySnapshot.exists()) {
      links.contract_legal = querySnapshot.data().contract_legal;
      links.link_text1 = querySnapshot.data().link_text1;
      links.link_text2 = querySnapshot.data().link_text2;
      links.link_text3 = querySnapshot.data().link_text3;
      links.link_url1 = querySnapshot.data().link_url1;
      links.link_url2 = querySnapshot.data().link_url2;
      links.link_url3 = querySnapshot.data().link_url3;
      links.sepa_legal = querySnapshot.data().sepa_legal;
    }

    return links;
  } catch (error) {
    console.log(error);
  }
}

async function upload_csv_products(formData) {
  const requestOptions = {
    method: "POST",
    body: formData,
  };
  return fetch(
    `https://www.x-cite-web.de:5000/api/products/upload`,
    requestOptions
  ).then(handleResponse);
}

async function getBackupList() {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `https://www.x-cite-web.de:5000/api/products/backup/list`,
    requestOptions
  ).then(handleResponse);
}
async function update_products(object) {
  const requestOptions = {
    method: "POST",
    body: object,
    headers: {
      "Content-type": "application/json",
    },
  };
  return fetch(
    `https://www.x-cite-web.de:5000/api/products/update`,
    requestOptions
  ).then(handleResponse);
}
async function execute_back_up(object) {
  const requestOptions = {
    method: "POST",
    body: object,
    headers: {
      "Content-type": "application/json",
    },
  };
  return fetch(
    `https://www.x-cite-web.de:5000/api/products/execute_back_up`,
    requestOptions
  ).then(handleResponse);
}
async function create_backup(object) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `https://www.x-cite-web.de:5000/api/products/create_dump`,
    requestOptions
  ).then(handleResponse);
}
// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
async function getUserProductGroups(contract) {
  let productList = [];
  let product_group_ids = [];
  const userDoc = await userService.getUser(userService.getId());

  userDoc.forEach(async (doc) => {
    let Product_group_ids = doc.data().product_group.map((element) => {
      if (element.isActive == 1) return element.id_product;
    });
    product_group_ids.push(...Product_group_ids);
  });
  if (product_group_ids.length > 0) {
    let product_groups = await productService.getProductGroupListByType(
      contract.contract_type
    );
    let filtredData = product_groups.filter((item) =>
      product_group_ids.includes(item.id)
    );

    let ProductList = await getZipCode(filtredData, product_groups, contract);
    productList.push(...ProductList);
  }
  return productList;
}

const getZipCode = async (filtredData, product_groups, contract) => {
  let plzArray = await zipCodeService.getZipCode(contract.postal_code);
  const plzIds = plzArray.map((plz) => plz.id);
  // if (plzIds.length > 0) setEmpty(false);
  // else setEmpty(true);
  let productList = await getProduct(
    plzIds,
    filtredData,
    product_groups,
    contract
  );
  return productList;
};
const getProduct = async (
  zipCodeData,
  filtredData,
  product_groups,
  contract
) => {
  try {
    if (zipCodeData.length > 0) {
      let productArray = await productService.getProductList(zipCodeData);
      //filter productArray by checking if any object  product_groups has an id matching the product_group[0] of the current object being iterated in productArray
      let product_list = productArray
        .filter((element) =>
          filtredData.some((group) => group.id === element.product_group[0])
        )
        .filter(
          (element) =>
            element.consumption_itnervall_to > contract.consumption &&
            contract.consumption > element.consumption_intervall_from
        );
      return product_list;
    }
  } catch (error) {
    console.log("error occured");
  }
};
async function SuggestTariff(contract) {
  let products = await getUserProductGroups(contract);
  return products;
}
/***************suggest tariff with priority */

/******************* */
async function getUserProductGroupsWithPriority(contract, priority) {
  let productList = [];
  let product_group_ids = [];
  const userDoc = await userService.getUser(userService.getId());

  userDoc.forEach(async (doc) => {
    let Product_group_ids = doc.data().product_group.map((element) => {
      if (element.isActive == 1) return element.id_product;
    });
    product_group_ids.push(...Product_group_ids);
  });

  if (product_group_ids.length > 0) {

    let product_groups =
      await productService.getSuggestedProductGroupListByType(
        contract.contract_type,
        priority
      );

    let filtredData = product_groups.filter((item) =>
      product_group_ids.includes(item.id)
    );
    let ProductList = await getZipCodeWithPriority(
      filtredData,
      product_groups,
      contract,
      priority
    );
    productList.push(...ProductList);
  }

  return productList;
}
const getZipCodeWithPriority = async (
  filtredData,
  product_groups,
  contract,
  priority
) => {
  let plzArray = await zipCodeService.getZipCode(contract.postal_code);
  const plzIds = plzArray.map((plz) => plz.id);
  let productList = await getProductWithPriority(
    plzIds,
    filtredData,
    product_groups,
    contract,
    priority
  );
  return productList;
};
const getProductWithPriority = async (
  zipCodeData,
  filtredData,
  product_groups,
  contract,
  priority
) => {
  try {
    if (zipCodeData.length > 0) {
      let productArray = await productService.getProductList(zipCodeData);
      //filter productArray by checking if any object  product_groups has an id matching the product_group[0] of the current object being iterated in productArray
      let product_list = productArray
        .filter((element) =>
          filtredData.some((group) => group.id === element.product_group[0])
        )
        .filter(
          (element) =>
            element.consumption_itnervall_to > contract.consumption &&
            contract.consumption > element.consumption_intervall_from
        );
      if (product_list.length <= 0 && priority <= 2) {
        let standard = priority + 1;
        product_list = await getUserProductGroupsWithPriority(contract, standard);

      }
      return product_list;
    } else return []
  } catch (error) {
    console.log("error occured");
  }
};
async function SuggestTarifWithPriority(contract, priority) {
  let products = await getUserProductGroupsWithPriority(contract, priority);
  return products;
}

async function checkProductAvailable(product_id) {
  let isValid = true;
  let product = (await getProductById(product_id)).data();
  if (product && product.is_deleted === true) {
    isValid = false;
    return isValid;
  } else {
    let productGroup = getProductGroupById(product.product_group[0]);
    if (productGroup.active === 0 || productGroup.is_deleted) {
      isValid = false;
      return isValid;
    }
  }
  return isValid;
}

function getTotalCostParMonth(obj, consumption) {
  let totalCost =
    (obj.basic_charge * 12 +
      (consumption * (obj.kwh_price / 100) - obj.bonus)) /
    12;

  return totalCost.toFixed(2).toString();
};
